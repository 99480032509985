import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  TitleCasePipe,
} from '@angular/common'
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiAccounting } from '@api/api.accounting'
import { ApiContract } from '@api/api.contract'
import {
  Contract,
  Expense,
  ResolveExpense,
} from '@interfaces/accounting.interface'
import { EmployeeCompany } from '@interfaces/contract.interface'
import { Person } from '@interfaces/personnel-management.interface'
import { ReportPayment, ReportPerUser } from '@interfaces/report.interface'
import { ApiService } from '@services/api.service'
import { ToastService } from '@services/toast.service'
import { TABLE_FILE_NAMES } from '@utils/p-table-names'
import { ConfirmationService } from 'primeng/api'
import { AvatarModule } from 'primeng/avatar'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { ChipModule } from 'primeng/chip'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DialogModule } from 'primeng/dialog'
import { FloatLabelModule } from 'primeng/floatlabel'
import { InputTextModule } from 'primeng/inputtext'
import { TableModule } from 'primeng/table'
import { TooltipModule } from 'primeng/tooltip'
import { PadNumberPipe } from 'src/app/utils/pad-number.pipe'
import * as XLSX from 'xlsx'
import { HourlyReportComponent } from './hourly-report/hourly-report.component'

@Component({
  selector: 'app-info-panel',
  standalone: true,
  imports: [
    FormsModule,
    FloatLabelModule,
    InputTextModule,
    CalendarModule,
    ButtonModule,
    TooltipModule,
    TableModule,
    DialogModule,
    AvatarModule,
    ChipModule,
    TitleCasePipe,
    CurrencyPipe,
    DecimalPipe,
    DatePipe,
    PadNumberPipe,
    HourlyReportComponent,
    ConfirmDialogModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './info-panel.component.html',
  styleUrl: './info-panel.component.scss',
  host: {
    class: 'flexible',
  },
})
export class InfoPanelComponent implements OnChanges {
  @ViewChild(HourlyReportComponent)
  hourlyReportComponent?: HourlyReportComponent

  @Input() employee: Person | undefined
  @Input() company: EmployeeCompany | undefined

  isPaymentDisabled = true
  today = new Date()
  searchDate: Date | undefined
  report: ReportPerUser = {
    asistencias: [],
    ingresos: [],
    egresos: [],
    totalHoras: 0,
    resultado_ingresos: 0,
    resultado_egresos: 0,
    subTotal: 0,
    total: 0,
  }

  table_name = ''
  income_columns = ['concepto', 'monto', 'fecha solicitud', 'acciones'].map(
    (column) => column.toLowerCase(),
  )
  expense_columns = [...this.income_columns]

  expenseToDisplay: Expense | undefined
  displayVisible = false
  isHourlyReportVisible = false

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private srvAPI: ApiService,
    private srvToast: ToastService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const employee = changes['employee']?.currentValue as Person
    if (!!employee) this.employee = employee

    const company = changes['company']?.currentValue as EmployeeCompany
    if (!!company) this.company = company

    if (!!employee && !!company) {
      this.searchDate = new Date()
      const month = +this.route.snapshot.queryParams['month']
      const year = +this.route.snapshot.queryParams['year']

      // month in 0-11 index then -1
      if (!!month && !!year) this.searchDate.setFullYear(year, month - 1, 1)
      else if (!!month) this.searchDate.setMonth(month - 1)

      this.loadData()
    }
  }

  loadData() {
    this.isPaymentDisabled = true
    if (!!!this.searchDate) return
    this.searchDate.setMonth(this.searchDate.getMonth() + 1)
    this.searchDate.setDate(-1)

    // month in 0-11 index then +1
    const path = `?month=${
      this.searchDate.getMonth() + 1
    }&year=${this.searchDate.getFullYear()}`

    const url = `/dashboard/report/per-user/${this.employee?.cuenta?._id}${
      !!this.company?.nombre ? `/${this.company?.nombre}${path}` : ''
    }`
    this.router.navigateByUrl(url)

    this.srvAPI
      .get(
        `${ApiContract.obtenerReporteDePago}/${this.company?._id}/${
          this.employee?.cuenta._id
        }/${this.searchDate.toISOString().split('T')[0]}`,
      )
      .subscribe((response: any) => {
        this.report = response['data'] as ReportPerUser

        // payment controls
        this.isPaymentDisabled =
          // if total is lesser than 0
          this.report.total <= 0 ||
          // if it's already paid
          !!this.report.pago ||
          // if expense aren't paid yet
          !this.areExpensesResolved ||
          // if there's not searchDate (no month selected)
          !!!this.searchDate ||
          // if searchDate >= today then disable payment
          (this.searchDate &&
            this.searchDate.getFullYear() >= this.today.getFullYear() &&
            this.searchDate.getMonth() >= this.today.getMonth())
      })
  }

  get areExpensesResolved() {
    // if expense forma = fijo (1) and estado = resolver (0)
    return (
      this.report.egresos.findIndex(
        (ex) => ex.forma === 1 && ex.estado === 0,
      ) === -1
    )
  }

  viewExpense(expense: Expense) {
    this.expenseToDisplay = this.report.egresos.find(
      (item) => item._id === expense._id,
    ) as Expense | undefined
    if (!!!this.expenseToDisplay) return

    this.expenseToDisplay.contrato = {
      cuenta: {
        persona: {
          nombre: this.employee?.nombre,
          apellido: this.employee?.apellido,
          cedula: this.employee?.cuenta.usuario,
        },
      },
      empresa: { nombre: this.company?.nombre },
    } as Contract
    this.expenseToDisplay.pago = expense.monto
    this.expenseToDisplay.updated_at = new Date().toISOString().split('T')[0]
    this.displayVisible = true
  }

  resolveExpense(estado: 1 | 2, expense: Expense) {
    const resolve = {
      estado,
      respuesta: expense.respuesta,
    } as ResolveExpense

    this.srvAPI
      .patch(`${ApiAccounting.resolverEgreso}/${expense._id}`, resolve)
      .subscribe({
        next: (response: any) => {
          this.srvToast.info({
            title: response.status,
            message: 'Egreso resuelto',
          })
          this.loadData()
          this.displayVisible = false
        },
        error: (error) => {
          this.srvToast.error({
            title: `${error.error.statusCode} ${error.error.error}`,
            message: error.error.message,
          })
        },
      })
  }

  goToEmployee() {
    this.router.navigateByUrl(
      `/dashboard/personnel-management/${this.employee?.cuenta._id}`,
    )
  }

  createPayment(event: Event) {
    // payment controls
    if (this.isPaymentDisabled || !!!this.searchDate) return

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Esta acción es irreversible, ¿comfirma el pago?',
      header: 'Confirmar el Pago',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'PAGAR',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'bg-[#22c55e] border-none rounded-full',
      rejectButtonStyleClass: 'p-button-text text-black rounded-full',
      accept: () => {
        const payment: ReportPayment = {
          fecha_pago: new Date().toISOString(),
          fecha_periodo: this.searchDate!.toISOString(),
          total: this.report.total,
          sub_total: this.report.subTotal,
          ingresos: this.report.resultado_ingresos,
          egresos: this.report.resultado_egresos,
          horas_laboradas: this.report.totalHoras,
          descripcion: '',
          estado: 0,
        }

        this.srvAPI
          .post(
            `${ApiContract.registrarPago}/${this.company?.contrato._id}`,
            payment,
          )
          .subscribe({
            next: (response: any) => {
              this.srvToast.success({
                title: response.status,
                message: 'Pago registrado',
              })
              this.loadData()
            },
            error: (error) => {
              this.srvToast.error({
                title: `Error ${error.status}`,
                message: error.error.message,
              })
            },
          })
      },
    })
  }

  exportReport() {
    const workbook = XLSX.utils.book_new()

    const reportWs = XLSX.utils.json_to_sheet([
      {
        'Total horas laboradas': this.report.totalHoras
          ? `${this.report.totalHoras} h`
          : 'N/A',
        Subtotal: this.report.subTotal ? `$${this.report.subTotal}` : 'N/A',
        Ingresos: this.report.resultado_ingresos
          ? `+ $${this.report.resultado_ingresos}`
          : 'N/A',
        Egresos: this.report.resultado_egresos
          ? `- $${this.report.resultado_egresos}`
          : 'N/A',
        'A recibir': this.report.total ? `$${this.report.total}` : 'N/A',
      },
    ])
    XLSX.utils.book_append_sheet(workbook, reportWs, 'Resumen')

    const estados: any = {
      0: 'Pendiente',
      1: 'Aprobado',
      2: 'Rechazado',
    }

    // Second sheet - Ingresos
    const ingresosWs = XLSX.utils.json_to_sheet(
      this.report.ingresos.map((item) => ({
        concepto: item.concepto,
        monto: item.monto,
        created_at: item.created_at ? item.created_at.split('T')[0] : 'N/A',
        estado: item.forma == 0 ? 'Recurrente' : estados[item.estado],
      })),
    )
    XLSX.utils.book_append_sheet(workbook, ingresosWs, 'Ingresos')

    // Third sheet - Egresos
    const egresosWs = XLSX.utils.json_to_sheet(
      this.report.egresos.map((item) => ({
        concepto: item.concepto,
        monto: item.monto,
        created_at: item.created_at ? item.created_at.split('T')[0] : 'N/A',
        estado: item.forma == 0 ? 'Recurrente' : estados[item.estado],
      })),
    )
    XLSX.utils.book_append_sheet(workbook, egresosWs, 'Egresos')

    // Generate an Excel file
    const company = !!this.company
      ? ` ${this.company.nombre.toUpperCase()}`
      : ''
    const employee = !!this.employee
      ? ` ${this.employee.nombre} ${this.employee.apellido}`
      : ''
    const date = !!this.searchDate
      ? ` ${(this.searchDate.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${this.searchDate.getFullYear().toString()}`
      : ''

    this.table_name = `${TABLE_FILE_NAMES['user_report']}${company}${date}${employee}.xlsx`

    XLSX.writeFile(workbook, this.table_name)
  }

  exportToCSV() {
    if (!!this.hourlyReportComponent) this.hourlyReportComponent.exportToCSV()
  }
}
