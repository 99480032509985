import { DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ApiTimeRecords } from '@api/api.time-records'
import { EmployeeCompany } from '@interfaces/contract.interface'
import { pTableColumn, pTableTimeRecord } from '@interfaces/p-table.interface'
import { Person } from '@interfaces/personnel-management.interface'
import { ReportPerUser, TimeRecord } from '@interfaces/report.interface'
import { ApiService } from '@services/api.service'
import { convertDatesToUTC } from '@utils/date-converter'
import { hasMoreThanTwoDecimals } from '@utils/decimal-checker'
import { TABLE_FILE_NAMES } from '@utils/p-table-names'
import { CalendarModule } from 'primeng/calendar'
import { Table, TableModule } from 'primeng/table'
import { TooltipModule } from 'primeng/tooltip'

@Component({
  selector: 'app-hourly-report',
  standalone: true,
  imports: [
    FormsModule,
    CalendarModule,
    TooltipModule,
    TableModule,
    TitleCasePipe,
    DatePipe,
    DecimalPipe,
  ],
  templateUrl: './hourly-report.component.html',
  styleUrl: './hourly-report.component.scss',
  host: {
    class: 'flexible',
  },
})
export class HourlyReportComponent implements OnInit {
  @ViewChild('pTable') pTable?: Table

  @Input() report: ReportPerUser | undefined
  @Input() employee: Person | undefined
  @Input() company: EmployeeCompany | undefined
  @Input() searchDate: Date | undefined

  @Output() loadData: EventEmitter<void> = new EventEmitter<void>()
  @Output() searchDateChange: EventEmitter<Date | undefined> = new EventEmitter<
    Date | undefined
  >()

  onDateChange(searchDate: Date | undefined) {
    this.searchDateChange.emit(searchDate)
    this.loadData.emit()
    this.initData()
  }

  table_name = ''
  columns: pTableColumn[] = [
    { field: 'created_at', header: 'fecha' },
    { field: 'cantidad', header: 'horas laboradas' },
    { field: 'entrada', header: 'hora entrada' },
    { field: 'salida', header: 'hora salida' },
  ]

  pTableData: pTableTimeRecord[] = []
  time_records: TimeRecord[] = []

  constructor(private srvAPI: ApiService) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    this.srvAPI
      .get(
        `${ApiTimeRecords.contratoAsistencia}/${
          this.company?.contrato._id
        }?mes=${
          !!this.searchDate ? this.searchDate?.getMonth() + 1 : 0
        }&anio=${this.searchDate?.getFullYear()}`,
      )
      .subscribe((response: any) => {
        this.time_records = convertDatesToUTC(
          response['data'] as TimeRecord[],
          ['created_at'],
        )
        this.loadTable()
      })
  }

  loadTable() {
    // Generate an Excel file
    const company = !!this.company
      ? ` ${this.company.nombre.toUpperCase()}`
      : ''
    const employee = !!this.employee
      ? ` ${this.employee.nombre} ${this.employee.apellido}`
      : ''
    const date = !!this.searchDate
      ? ` ${(this.searchDate.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${this.searchDate.getFullYear().toString()}`
      : ''

    this.table_name = `${TABLE_FILE_NAMES['hourly_report']}${company}${date}${employee}.xlsx`

    this.pTableData = this.time_records.map((item) => {
      return {
        _id: item._id,
        created_at: new Date(item.created_at),
        entrada: item.entrada,
        salida: item.salida,
        cantidad: item.cantidad,
      } as pTableTimeRecord
    })
  }

  exportToCSV() {
    this.pTableData.forEach((record) => {
      if (!!record.created_at)
        record.created_at = Intl.DateTimeFormat('es-EC', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(record.created_at as Date)
    })

    this.pTable?.exportCSV()
    this.loadTable()
  }

  getTooltipNotification(num?: number) {
    if (!!!num) return

    return num != 0 && hasMoreThanTwoDecimals(num) ? num.toString() : undefined
  }
}
