import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import { ApiContract } from '@api/api.contract'
import { pTableColumn, pTableUsersOverall } from '@interfaces/p-table.interface'
import { ReportUserOverall } from '@interfaces/report.interface'
import { ApiService } from '@services/api.service'
import { convertDatesToUTC } from '@utils/date-converter'
import { CalendarModule } from 'primeng/calendar'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputTextModule } from 'primeng/inputtext'
import { Table, TableModule } from 'primeng/table'
import { TABLE_FILE_NAMES } from 'src/app/utils/p-table-names'

@Component({
  selector: 'app-users-overall',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TableModule,
    TitleCasePipe,
    CurrencyPipe,
    DatePipe,
  ],
  templateUrl: './users-overall.component.html',
  styleUrl: './users-overall.component.scss',
  host: {
    class: 'flexible',
  },
})
export class UsersOverallComponent implements OnInit {
  // MARK: class members
  // table
  table_name = TABLE_FILE_NAMES['users_overall']
  searchDate: Date | undefined
  columns: pTableColumn[] = [
    { field: 'cedula', header: 'identificación' },
    { field: 'usuario', header: 'usuario' },
    { field: 'empresa', header: 'empresa' },
    { field: 'telefono', header: 'teléfono' },
    { field: 'estado', header: 'estado' },
    { field: 'created_at', header: 'asignación' },
    { field: 'deleted_at', header: 'remoción' },
    { field: 'pago', header: 'pago' },
    { field: 'ingresos', header: 'ingresos' },
    { field: 'egresos', header: 'egresos' },
    { field: 'total', header: 'último pago' },
    { field: 'fecha_pago', header: 'fecha de pago' },
  ].map((column) => {
    return { field: column.field, header: column.header.toLowerCase() }
  })
  pTableData: pTableUsersOverall[] = []
  report: ReportUserOverall[] = []

  constructor(private router: Router, private srvAPI: ApiService) {}

  ngOnInit() {
    this.srvAPI.get(ApiContract.obtenerTodos).subscribe((response: any) => {
      this.report = response['data'] as ReportUserOverall[]
      for (const report_row of this.report) {
        report_row.contrato = convertDatesToUTC(
          [report_row.contrato],
          ['created_at', 'deleted_at'],
        )[0]

        if (report_row.pago)
          report_row.pago = convertDatesToUTC(
            [report_row.pago],
            ['fecha_pago'],
          )[0]
      }
      this.loadTable()
    })
  }

  loadTable() {
    this.pTableData = this.report.map((item) => {
      return {
        cuenta_id: item.contrato.cuenta._id,
        cedula: item.contrato.cuenta.usuario,
        usuario: `${item.contrato.cuenta.persona.nombre} ${item.contrato.cuenta.persona.apellido}`,
        empresa: item.contrato.empresa.nombre,
        telefono: item.contrato.cuenta.persona.telefono,
        estado: item.contrato.estado === 1 ? 'Activo' : 'Inactivo',
        created_at: !!item.contrato.created_at
          ? new Date(item.contrato.created_at)
          : item.contrato.created_at,
        deleted_at: !!item.contrato.deleted_at
          ? new Date(item.contrato.deleted_at)
          : item.contrato.deleted_at,
        pago: !!item.pago ? 'Pagado' : 'Pendiente',
        ingresos: item.pago?.ingresos,
        egresos: item.pago?.egresos,
        total: item.pago?.total,
        fecha_pago: !!item.pago?.fecha_pago
          ? new Date(item.pago.fecha_pago)
          : '',
      } as pTableUsersOverall
    })
  }

  filterByDate(value: Date, field: keyof pTableUsersOverall) {
    this.loadTable()
    this.pTableData = this.pTableData.filter((record) => {
      const recordDate = new Date(record[field])
      return recordDate.toDateString() === value.toDateString()
    })
  }

  exportToCSV(pTable: Table) {
    this.pTableData.forEach((record) => {
      if (!!record.created_at)
        record.created_at = Intl.DateTimeFormat('es-EC', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(record.created_at as Date)

      if (!!record.deleted_at)
        record.deleted_at = Intl.DateTimeFormat('es-EC', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(record.deleted_at as Date)

      if (!!record.fecha_pago)
        record.fecha_pago = Intl.DateTimeFormat('es-EC', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(record.fecha_pago as Date)
    })

    pTable.exportCSV()
    this.loadTable()
  }

  goToReport(cuenta_id: number, companyName?: string, created_at?: string) {
    try {
      let path = ''
      // month in 0-11 index then +1
      if (!!created_at) {
        const date = new Date(created_at)
        path = `?month=${date.getMonth() + 1}&year=${date.getFullYear()}`
      }

      this.router.navigateByUrl(
        `/dashboard/report/per-user/${cuenta_id}${
          !!companyName && !!created_at ? `/${companyName}${path}` : ''
        }`,
      )
    } catch (error) {
      this.router.navigateByUrl(`/dashboard/report/per-user/${cuenta_id}`)
    }
  }
}
