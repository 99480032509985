<div class="flexible ring-1 ring-[gainsboro] rounded-2xl">
  <div class="flex items-center p-3 {{isHourlyReportVisible ? 'hover:cursor-pointer' : ''}}"
    (click)="isHourlyReportVisible && isHourlyReportVisible = false">
    @if (isHourlyReportVisible == true) {
    <p-button
      class="hidden sm:block"
      styleClass="text-nowrap p-0"
      type="button"
      icon="pi pi-arrow-left"
      severity="success"
      [text]="true" />
    }
    <p class="flex items-center flex-1">
      <span class="text-lg font-bold uppercase">{{ company?.nombre }}</span>
      <span class="w-[2px] h-[2px] mx-1 rounded-full bg-black"></span>
      <span class="text-lg font-bold capitalize">{{
        employee?.nombre + ' ' + employee?.apellido
        }}</span>
    </p>
  </div>

  @if (isHourlyReportVisible == true) {

  <app-hourly-report
    class="w-full p-3 flexible gap-y-5"
    [(searchDate)]="searchDate"
    [report]="report"
    [employee]="employee"
    [company]="company"
    (loadData)="loadData()"></app-hourly-report>

  } @else {
  <div class="relative w-full overflow-y-scroll flexible">
    <div class="absolute w-full p-3 flexible gap-y-5">
      <div class="flex justify-between">
        <p-calendar
          class="custom-p-calendar"
          [showButtonBar]="true"
          [(ngModel)]="searchDate"
          [showIcon]="true"
          inputId="icondisplay"
          dateFormat="mm-yy"
          view="month"
          (onSelect)="loadData()" />
        <p class="flex items-center mx-5 gap-x-3">
          @if (searchDate?.getFullYear() == today.getFullYear() &&
          searchDate?.getMonth() == today.getMonth()) {
          <span class="mx-1">Mes en progreso</span>
          <span class="pi pi-clock text-[grey]"></span>

          } @else if (!!report.pago) {
          <span class="mx-1">Pago realizado</span>
          <span class="pi pi-check text-[green]"></span>

          } @else {
          <span class="mx-1">Pendiente de pago</span>
          <span class="pi pi-exclamation-triangle text-[goldenrod]"></span>
          }
        </p>
      </div>

      <div class="flex flex-col flex-1 w-full py-5 gap-y-3">
        <p class="p-3 text-lg group hover:bg-aqua-color rounded-2xl" role="button"
          (click)="isHourlyReportVisible = true">

          <span class="flex items-center justify-between group-hover:hidden">
            <span><span class="mr-2 pi pi-clock"></span> Total horas laboradas</span>
            <span class="px-4 py-1 rounded-full bg-aqua-color">
              {{
              report.totalHoras < 1
                ? (report.totalHoras | number : '1.2-2' ) + ' h' || 'N/A'
                : (report.totalHoras | number : '1.0-0' ) + ' h' || 'N/A'
                }}
                </span>
            </span>

            <span class="items-center justify-between hidden group-hover:flex">
              <span><span class="mr-2 pi pi-clock"></span> Reporte por horas laboradas</span>
              <span class="px-4 py-1">
                <span class="pi pi-arrow-right"></span>
              </span>
            </span>

        </p>
        <p class="flex justify-between p-3 text-lg rounded-lg">
          <span><span class="mr-2 pi pi-money-bill"></span> Subtotal:</span>
          <span>$ {{ (report.subTotal | number : '1.2-2') || 'N/A' }}</span>
        </p>
        <p class="flex justify-between p-3 text-lg rounded-lg">
          <span><span class="mr-2 pi pi-angle-double-up"></span> Ingresos:</span>
          <span class="text-[limegreen]">+ $
            {{ (report.resultado_ingresos | number : '1.2-2') || 'N/A' }}</span>
        </p>
        <p class="flex justify-between p-3 text-lg rounded-lg">
          <span><span class="mr-2 pi pi-angle-double-down"></span> Egresos:</span>
          <span class="text-red-color">- $
            {{ (report.resultado_egresos | number : '1.2-2') || 'N/A' }}</span>
        </p>
        <p class="flex justify-between p-3 rounded-lg text-lg bg-[#FEF6F7]">
          <span><span class="mr-2 pi pi-dollar"></span> A recibir:</span>
          <span>$ {{ (report.total | number : '1.2-2') || 'N/A' }}</span>
        </p>
      </div>

      <div>
        <p>Ingresos</p>
        <p-table
          #pTable
          class="relative pb-3"
          styleClass="ring-[1px] ring-[gainsboro] rounded-[10px] text-nowrap my-2"
          tableStyleClass="min-w-[10px]"
          [value]="report.ingresos"
          [rows]="10">
          <ng-template pTemplate="header">
            <tr>
              @for (column of income_columns; track $index) {
              <!--  -->
              @if ($index !== income_columns.length - 1) {
              <th [pSortableColumn]="column" class="w-[25%]">
                {{ column | titlecase }}
                <p-sortIcon [field]="column" />
              </th>
              } @else {
              <th class="w-auto text-center">
                {{ column | titlecase }}
              </th>
              } }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-income>
            <tr>
              <td
                class="truncate max-w-[222px]"
                tooltipStyleClass="min-w-[222px] custom-tooltip"
                tooltipPosition="top"
                [pTooltip]="
                  income.concepto.length > 22 ? income.concepto : undefined
                ">
                {{ income.concepto }}
              </td>
              <td>{{ income.monto | currency }}</td>
              @if (income.forma == 1) {
              <td>{{ income.created_at | date : 'dd-MM-yyyy' }}</td>
              } @else {
              <td>{{ searchDate | date : 'MM-yyyy' }}</td>
              }
              <td
                class="flex items-center justify-center gap-x-1 custom-table-actions">
                @if (income.forma === 1) {
                <div class="flex justify-center w-full">--</div>
                } @else {
                <p-button
                  styleClass="bg-grey-color text-[black] py-1 px-5 border-none rounded-full"
                  tooltipPosition="left"
                  pTooltip="Actualizar desde Gestión de personal"
                  label="Recurrente"
                  (onClick)="goToEmployee()" />
                }
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div>
        <p>Egresos</p>
        <p-table
          #pTable
          class="relative pb-3"
          styleClass="ring-[1px] ring-[gainsboro] rounded-[10px] text-nowrap my-2"
          tableStyleClass="min-w-[10px]"
          [value]="report.egresos"
          [rows]="10">
          <ng-template pTemplate="header">
            <tr>
              @for (column of expense_columns; track $index) {
              <!--  -->
              @if ($index !== expense_columns.length - 1) {
              <th [pSortableColumn]="column" class="w-[25%]">
                {{ column | titlecase }}
                <p-sortIcon [field]="column" />
              </th>
              } @else {
              <th class="w-auto text-center">
                {{ column | titlecase }}
              </th>
              } }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-expense>
            <tr>
              <td
                class="truncate max-w-[222px]"
                tooltipStyleClass="min-w-[222px] custom-tooltip"
                tooltipPosition="top"
                [pTooltip]="
                  expense.concepto.length > 22 ? expense.concepto : undefined
                ">
                {{ expense.concepto }}
              </td>
              <td>{{ expense.monto | currency }}</td>
              @if (expense.forma == 1) {
              <td>{{ expense.created_at | date : 'dd-MM-yyyy' }}</td>
              } @else {
              <td>{{ searchDate | date : 'MM-yyyy' }}</td>
              }
              <td class="custom-table-actions">
                <div class="flex items-center justify-center gap-x-1">
                  @if (expense.forma === 1) {
                  <!-- forma: fijo 1 -->
                  @switch (expense.estado) {
                  <!-- // resolver -->
                  @case (0) {
                  <p-button
                    styleClass="bg-[#FFFCEB] text-[#E5C100] py-1 px-5 border-none rounded-full"
                    label="Resolver"
                    (onClick)="viewExpense(expense)" />
                  }
                  <!-- // aprobado -->
                  @case (1) {
                  <p-button
                    styleClass="bg-[#EFFBF1] text-[#4CAF50] py-1 px-5 border-none rounded-full"
                    label="Aprobado"
                    (onClick)="viewExpense(expense)" />
                  }
                  <!-- // rechazado -->
                  @case (2) {
                  <p-button
                    styleClass="bg-[#FEEDEC] text-[#F44336] py-1 px-5 border-none rounded-full"
                    label="Rechazado"
                    (onClick)="viewExpense(expense)" />
                  } }
                  <!--  -->
                  } @else {
                  <p-button
                    styleClass="bg-grey-color text-[black] py-1 px-5 border-none rounded-full"
                    tooltipPosition="left"
                    pTooltip="Actualizar desde Gestión de personal"
                    label="Recurrente"
                    (onClick)="goToEmployee()" />
                  }
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  }
  <div class="relative flex items-center justify-end w-full p-3 gap-x-5">
    <p-button
      label="Descargar reporte"
      icon="pi pi-download"
      severity="danger"
      [rounded]="true"
      [outlined]="true"
      (onClick)="!isHourlyReportVisible ? exportReport() : exportToCSV()" />
    <p-button
      [hidden]="isHourlyReportVisible"
      [label]="
        !!report.pago
          ? 'Pagado'
          : searchDate?.getFullYear() == today.getFullYear() &&
            searchDate?.getMonth() == today.getMonth()
          ? 'En progreso'
          : 'Pagar'
      "
      [icon]="
        !!report.pago
          ? 'undefined'
          : searchDate?.getFullYear() == today.getFullYear() &&
            searchDate?.getMonth() == today.getMonth()
          ? 'pi pi-clock'
          : 'pi pi-dollar'
      "
      [severity]="
        !!report.pago
          ? 'success'
          : searchDate?.getFullYear() == today.getFullYear() &&
            searchDate?.getMonth() == today.getMonth()
          ? 'warning'
          : 'danger'
      "
      [rounded]="true"
      [disabled]="isPaymentDisabled"
      (onClick)="createPayment($event)" />
  </div>
</div>

<!-- MARK: MODALS -->
<div class="flex items-center justify-center">
  <p-confirmDialog />
</div>

<p-dialog
  styleClass="min-w-[20vw] max-w-[25vw]"
  [(visible)]="displayVisible"
  header="Otros egresos"
  [modal]="true"
  [focusOnShow]="false"
  (onHide)="expenseToDisplay = undefined; displayVisible = false">
  <div class="flex flex-col items-center justify-center px-3">
    <p-chip
      styleClass="px-5 flex justify-between items-center bg-aqua-color"
      role="button">
      <p-avatar
        [label]="
          (expenseToDisplay?.contrato?.cuenta?.persona?.nombre &&
            (expenseToDisplay?.contrato?.cuenta?.persona?.nombre)![0]) ||
          ''
        "
        styleClass="mr-2 bg-[gainsboro] rounded-full"
        size="xlarge" />
      <p class="hidden md:block">
        <span>{{
          expenseToDisplay?.contrato?.cuenta?.persona?.nombre +
          ' ' +
          expenseToDisplay?.contrato?.cuenta?.persona?.apellido
          }}</span><br />
        <span class="text-sm capitalize">{{
          expenseToDisplay?.contrato?.cuenta?.persona?.cedula
          }}</span>
      </p>
    </p-chip>
    <p class="py-5 text-5xl">
      {{ expenseToDisplay?.pago | currency }}
    </p>
    <p class="text-lg">
      <span class="pi pi-hashtag"></span>
      <span class="mx-5">{{ expenseToDisplay?._id | padNumber }}</span>
    </p>
    <p class="flex justify-between w-full my-3 text-lg">
      <span><span class="mx-2 pi pi-building"></span> Empresa:</span>
      <span>{{ expenseToDisplay?.contrato?.empresa?.nombre }}</span>
    </p>
    <p class="flex justify-between w-full my-3 text-lg">
      <span><span class="mx-2 pi pi-receipt"></span> Concepto</span>
      <span>{{ expenseToDisplay?.concepto }}</span>
    </p>
    <p class="flex justify-between w-full my-3 text-lg">
      <span><span class="mx-2 pi pi-calendar"></span> Fecha de registro:</span>
      <span>{{ expenseToDisplay?.created_at | date : 'dd-MM-yyyy' }}</span>
    </p>

    <!-- estado -->
    @switch (expenseToDisplay?.estado) {
    <!-- aprobado -->
    @case (1) {
    <p class="flex justify-between w-full my-3 text-lg">
      <span><span class="mx-2 pi pi-calendar"></span> Fecha de respuesta:</span>
      <span>{{ expenseToDisplay?.updated_at | date : 'dd-MM-yyyy' }}</span>
    </p>
    <p class="w-full my-3 text-lg text-justify">
      R: {{ expenseToDisplay?.respuesta }}
    </p>
    <p class="flex justify-between w-full my-3 text-lg">
      <span>Estado:</span>
      <span class="py-1 px-5 rounded-full bg-[#EFFBF1] text-[#4CAF50]"><span class="pi pi-check"></span> Aprobado</span>
    </p>

    }
    <!-- rechazado -->
    @case (2) {
    <p class="flex justify-between w-full my-3 text-lg">
      <span><span class="mx-2 pi pi-calendar"></span> Fecha de respuesta:</span>
      <span>{{ expenseToDisplay?.updated_at | date : 'dd-MM-yyyy' }}</span>
    </p>
    <p class="w-full my-3 text-lg text-justify">
      R: {{ expenseToDisplay?.respuesta }}
    </p>
    <p class="flex justify-between w-full my-3 text-lg">
      <span>Estado:</span>
      <span class="py-1 px-5 rounded-full bg-[#FEEDEC] text-[#F44336]"><span class="pi pi-times"></span>
        Rechazado</span>
    </p>
    }
    <!-- resolver -->
    @case (0) { @if (!!expenseToDisplay) {
    <p-floatLabel class="w-full py-8">
      <input
        class="w-full"
        pInputText
        [(ngModel)]="expenseToDisplay.respuesta"
        id="concept"
        type="text"
        name="concept" />
      <label for="concept">Respuesta</label>
    </p-floatLabel>
    <div class="flex items-center justify-center gap-x-5">
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Aprobar"
        icon="pi pi-check"
        severity="success"
        [rounded]="true"
        [outlined]="true"
        (onClick)="resolveExpense(1, expenseToDisplay)" />
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Rechazar"
        icon="pi pi-times"
        severity="danger"
        [rounded]="true"
        [outlined]="true"
        (onClick)="resolveExpense(2, expenseToDisplay)" />
    </div>
    } } }
  </div>
</p-dialog>