<p-calendar
  class="custom-p-calendar hourly-report-calendar"
  [showButtonBar]="true"
  [(ngModel)]="searchDate"
  [showIcon]="true"
  inputId="icondisplay"
  dateFormat="mm-yy"
  view="month"
  (onSelect)="loadTable()"
  (ngModelChange)="onDateChange($event)" />

<div class="flex items-center self-center justify-center py-2 gap-x-5">
  <div class="flex flex-col w-full p-3 rounded-md shadow-sm hover:shadow-md flex-nowrap text-nowrap">
    <p class="flex items-center justify-between w-full text-lg font-bold">
      <span>$ {{ company?.contrato?.valor }}</span>
      <span class="ml-2 text-lg pi pi-clock text-[goldenrod]"></span>
    </p>
    <p>Valor (hora)</p>
  </div>
  <div class="flex flex-col w-full p-3 rounded-md shadow-sm hover:shadow-md flex-nowrap text-nowrap">
    <p class="flex items-center justify-between w-full text-lg font-bold">
      <span>{{ company?.contrato?.descanso }} min</span>
      <span class="ml-2 text-lg pi pi-heart text-[red]"></span>
    </p>
    <p>Descanso</p>
  </div>
  <div class="flex flex-col w-full p-3 rounded-md shadow-sm hover:shadow-md flex-nowrap text-nowrap"
    [pTooltip]="getTooltipNotification(report?.totalHoras)"
    tooltipPosition="top">
    <p class="flex items-center justify-between w-full text-lg font-bold">
      <span>{{ report?.totalHoras | number: '1.2-4'}} hrs</span>
      <span class="ml-2 text-lg pi pi-briefcase text-[chocolate]"></span>
    </p>
    <p>Total laboradas</p>
  </div>
  <div class="flex flex-col w-full p-3 rounded-md shadow-sm hover:shadow-md flex-nowrap text-nowrap"
    [pTooltip]="getTooltipNotification(report?.subTotal)"
    tooltipPosition="top">
    <p class="flex items-center justify-between w-full text-lg font-bold">
      <span>$ {{ report?.subTotal | number: '1.2-4'}}</span>
      <span class="ml-2 text-lg pi pi-money-bill text-[green]"></span>
    </p>
    <p>Ganado (hora)</p>
  </div>
</div>

<div class="max-w-[100vw] relative flexible">
  <p-table
    #pTable
    class="absolute inset-0 p-5 pt-0 overflow-y-scroll flexible"
    styleClass="ring-[1px] ring-[gainsboro] rounded-[10px] text-nowrap my-2"
    tableStyleClass="min-w-[10px]"
    selectionMode="single"
    [scrollable]="true"
    [columns]="columns"
    [value]="pTableData"
    [exportFilename]="table_name"
    [paginator]="true"
    [alwaysShowPaginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50]"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{last} de {totalRecords}">
    <ng-template pTemplate="header">
      <tr>
        @for (column of columns; track $index) { @if ($index !==
        columns.length - 1) {
        <th [pSortableColumn]="column.field" class="w-[25%]">
          {{ column.header | titlecase }}
          <p-sortIcon [field]="column.field" />
        </th>
        } @else {
        <th class="auto">
          {{ column.header | titlecase }}
        </th>
        } }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record>
      <tr>
        <td>{{ record.created_at | date : 'dd-MM-yyyy' }}</td>
        <td>{{ record.cantidad }}</td>
        <td>{{ record.entrada }}</td>
        <td>{{ record.salida }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>