import { env } from '@env/dev.env'

/**
 * GET contrato/{contrato_id}
 * GET obtener-todas
 * POST crear-asistencia
 * PATCH editar-asistencia/{asistencia_id}
 * DELETE eliminar-asistencia/{asistencia_id}
 */
export const ApiTimeRecords = {
  contratoAsistencia: `${env.api_url}asistencia/contrato`,
  obtenerTodas: `${env.api_url}asistencia/obtener-todas`,
  crearAsistencia: `${env.api_url}asistencia/crear-asistencia`,
  editarAsistencia: `${env.api_url}asistencia/editar-asistencia`,
  eliminarAsistencia: `${env.api_url}asistencia/eliminar-asistencia`,
}
